import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Calendar as CalendarBig,
  dateFnsLocalizer,
  Views,
} from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import {
  Box,
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Text,
  SimpleGrid,
  GridItem,
  Select,
  useToast,
} from '@chakra-ui/react';
import 'assets/css/ReactBigCalendar.css';
import enUS from 'date-fns/locale/en-US';
import { MdAddCircle } from 'react-icons/md';
import FormComponent from './FormComponent';
import useTimeline from 'hooks/useTimeline';
import DateInputForm from 'components/fields/DateInputForm';
import { Formik } from 'formik';

export const locales = {
  'en-US': enUS,
  // Add other locales here
};

const DragAndDropCalendar = withDragAndDrop(CalendarBig);

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date()),
  getDay,
  locales,
});

const events = [
  {
    id: 20,
    // title: 'Meeting 20',
    start: new Date(2024, 10, 20), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 10, 20), // July 14, 2024 at 12:00 PM
  },
  // Add more events here
];

export default function Timeline() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [myEvents, setMyEvents] = useState(events);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [platform, setPlatform] = useState('all');

  const handlePlatformChange = (event) => {
    setPlatform(event.target.value);
  };

  const toast = useToast();
  const { timeline, isLoading, getAllTimeline, editTimelineById } =
    useTimeline();

  useEffect(() => {
    handleFetchTimeline(currentDate);
  }, [currentDate, isLoading, platform]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: modalEventIsOpen,
    onOpen: modalEventOnOpen,
    onClose: modalEventOnClose,
  } = useDisclosure();
  
  const {
    isOpen: modalAddIsOpen,
    onOpen: modalAddOnOpen,
    onClose: modalAddOnClose,
  } = useDisclosure();

  const moveEvent = useCallback(
    async ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
      }
      if (allDay && !droppedOnAllDaySlot) {
        event.allDay = false;
      }

      console.log('event changed', event, start, end);

      const newDate = {
        date: start,
      };
      await editTimelineById(event.id, newDate);
      setCurrentDate(start);
    },
    [setMyEvents],
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });
    },
    [setMyEvents],
  );

  const handleFetchTimeline = async (event) => {
    const currentMonth = event.getMonth() + 1;
    const currentYear = event.getFullYear();

    await getAllTimeline(currentMonth, currentYear, platform);
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    modalEventOnOpen();
  };

  const handleMonthChange = async (event) => {
    setCurrentDate(event);
  };

  const initialValues = {
    new_date: 0,
  };

  const onSubmit = async (values) => {
    const transformedData = {
      date: values.new_date,
    };

    console.log('Transformed Data', transformedData);

    try {
      await editTimelineById(selectedEvent.id, transformedData);

      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
      modalEventOnClose();
    } catch (error) {
      console.log(error);
      toast({
        title: 'Cannot sending form!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Text fontWeight="bold" fontSize="34px">
        Timeline
      </Text>
      <Fragment>
        <Flex justifyContent="flex-end" mt={0}>
          <Select
            mr={4}
            name="platform"
            width="180px"
            value={platform}
            onChange={handlePlatformChange}
          >
            <option value="all">All</option>
            <option value="tiktok">Tiktok</option>
            <option value="instagram">Instagram</option>
            <option value="twitter">Twitter</option>
          </Select>
          <Button rightIcon={<MdAddCircle />} onClick={() => onOpen()}>
            Add New
          </Button>
        </Flex>
        <Box mt={5}>
          <DragAndDropCalendar
            date={currentDate}
            defaultView={Views.MONTH}
            events={timeline}
            localizer={localizer}
            onEventDrop={moveEvent}
            onEventResize={resizeEvent}
            onNavigate={handleMonthChange}
            popup
            resizable
            showAllEvents={true}
            onSelectEvent={handleSelectEvent}
            views={['month']}
          />
        </Box>

        {selectedEvent && (
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleChange, setFieldValue }) => (
              <Modal
                isOpen={modalEventIsOpen}
                onClose={modalEventOnClose}
                isCentered={true}
              >
                <ModalOverlay />
                <ModalContent maxWidth={{ base: '300px', md: '500px' }}>
                  <ModalHeader>{selectedEvent.title}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    {/* <Text>Start: {selectedEvent.start.toString()}</Text> */}

                    <DateInputForm
                      values={values}
                      label={'New Upload Date'}
                      id={'new_date'}
                      isArray={false}
                      setFieldValue={setFieldValue}
                      maxDateOn={false}
                      minDateToday={true}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      // type="submit"
                      colorScheme="blue"
                      mr={3}
                      onClick={() => onSubmit(values)}
                    >
                      Save
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            )}
          </Formik>
        )}

        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent maxWidth={{ base: '700px' }}>
            <ModalHeader>Add New Timeline</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormComponent onClose={onClose} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Fragment>
    </>
  );
}
