import React from 'react';
import {
  ChakraProvider,
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  Flex,
} from '@chakra-ui/react';

const FormComponentView = ({ cell, onClose }) => {
  return (
    <ChakraProvider>
      <Box p={5}>
        <form>
          <FormLabel fontSize="lg" fontWeight="bold">
            Rate Card
          </FormLabel>

          {cell.rate_card.map((rateCard, index) => (
            <SimpleGrid
              columns={3}
              spacingX={5}
              spacingY={5}
              mb="20px"
              key={index}
            >
              <GridItem>
                <FormLabel>Type</FormLabel>
                <Input
                  value={rateCard.sow_type.name}
                  textTransform="capitalize"
                  readOnly
                />
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isReadOnly>
                  <FormLabel>Price</FormLabel>
                  <Input value={rateCard.price} />
                </FormControl>
              </GridItem>
              <GridItem colSpan={3}>
                <FormControl isReadOnly>
                  <FormLabel>SOW</FormLabel>
                  <Input value={rateCard.sow} />
                </FormControl>
              </GridItem>
              <GridItem colSpan={3}>
                <FormControl isReadOnly mb={4}>
                  <FormLabel>Note</FormLabel>
                  <Input value={rateCard.note} />
                </FormControl>
              </GridItem>
            </SimpleGrid>
          ))}

          {cell.add_cost.length !== 0 && (
            <Flex justify="space-between">
              <Box>
                <FormLabel fontSize="lg" fontWeight="bold">
                  Additional Cost
                </FormLabel>
              </Box>
            </Flex>
          )}

          {cell.add_cost.map((cost, index) => (
            <SimpleGrid
              columns={3}
              spacingX={5}
              spacingY={5}
              mb="20px"
              key={index}
            >
              <GridItem>
                <FormControl isReadOnly>
                  <FormLabel>Type</FormLabel>
                  <Input value={cost.type} readOnly />
                </FormControl>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isReadOnly>
                  <FormLabel>Price</FormLabel>
                  <Input value={cost.price} />
                </FormControl>
              </GridItem>
            </SimpleGrid>
          ))}

          <SimpleGrid columns={3} mb="20px">
            <GridItem colSpan={3}>
              <FormControl mb={4}>
                <FormLabel fontSize="lg" fontWeight="bold">
                  Listing Note
                </FormLabel>
                <Input name="note" value="" />
              </FormControl>
            </GridItem>
          </SimpleGrid>
          <Box textAlign="right">
            <Button
              colorScheme="purple"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
          </Box>
        </form>
      </Box>
    </ChakraProvider>
  );
};

export default FormComponentView;
