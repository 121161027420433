import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { Formik, Form, FieldArray, Field } from 'formik';
import useDealing from 'hooks/useDealing';

const readOnlyColor = {
  bg: 'gray.100',
  color: 'gray.500',
  borderColor: 'gray.300',
  textTransform: 'capitalize',
};

const FormComponent = ({ onClose, cell }) => {
  const { postFinalDealing } = useDealing();
  const toast = useToast();

  useEffect(() => {
    console.log(cell);
  }, []);

  const initialValues = {
    username: cell.username,
    platform: cell.platform,
    kol_category: cell.kol_category,
    rate_card: cell.rate_card,
    rate_card_note: cell.rate_card.note,
    add_cost: cell.add_cost,
    note: cell.note,
  };

  const onSubmit = async (values) => {
    const transformedData = {
      final_cost_agreements: {
        rate_cards: values.rate_card.map((cost) => ({
          rate_card_id: cost.id,
          price: Number(cost.final_price),
          slot: Number(cost.slot),
        })),
        add_costs: values.add_cost.map((cost) => ({
          add_cost_id: cost.id,
          price: Number(cost.final_price),
        })),
      },
    };

    console.log('Transformed Data', transformedData);

    try {
      await postFinalDealing(transformedData, cell.id);
      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (error) {
      toast({
        title: 'Cannot sending form!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Box p={5}>
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <SimpleGrid columns={3} spacingX={5} spacingY={5} mb="20px">
                {/* Row 1 */}
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>Username</FormLabel>
                    <Input
                      readOnly
                      value={values.username}
                      _readOnly={readOnlyColor}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>Platform</FormLabel>
                    <Input
                      readOnly
                      value={values.platform}
                      _readOnly={readOnlyColor}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>KOL Category</FormLabel>
                    <Input
                      readOnly
                      value={values.kol_category}
                      _readOnly={readOnlyColor}
                    />
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              <Flex justify="space-between">
                <Box>
                  <FormLabel fontSize="lg" fontWeight="bold">
                    Rate Card
                  </FormLabel>
                </Box>
              </Flex>
              <FieldArray name="rate_card">
                {() => (
                  <>
                    {values.rate_card.map((rateCard, index) => (
                      <SimpleGrid
                        columns={3}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                        key={index}
                      >
                        <GridItem>
                          <FormLabel>Type</FormLabel>
                          <Input
                            value={rateCard.sow_type.name}
                            readOnly
                            _readOnly={readOnlyColor}
                          />
                        </GridItem>
                        <GridItem colSpan={2}>
                          <FormLabel>SOW</FormLabel>
                          <Input
                            value={rateCard.sow}
                            readOnly
                            _readOnly={readOnlyColor}
                          />
                        </GridItem>
                        <GridItem>
                          <FormLabel>Harga Nego</FormLabel>
                          <Input
                            value={rateCard.negotiated_rate_card?.price}
                            readOnly
                            _readOnly={readOnlyColor}
                          />
                        </GridItem>
                        <GridItem>
                          <FormControl isRequired>
                            <FormLabel>Harga Final</FormLabel>
                            <Field
                              name={`rate_card[${index}].final_price`}
                              as={Input}
                              placeholder="Enter price"
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl isRequired>
                            <FormLabel>Slot Final</FormLabel>
                            <Field
                              name={`rate_card[${index}].slot`}
                              as={Input}
                              placeholder={rateCard.negotiated_rate_card.slot}
                            />
                          </FormControl>
                        </GridItem>
                      </SimpleGrid>
                    ))}
                  </>
                )}
              </FieldArray>

              {values.add_cost.length !== 0 && (
                <Flex justify="space-between">
                  <Box>
                    <FormLabel mt={4} fontSize="lg" fontWeight="bold">
                      Add Cost
                    </FormLabel>
                  </Box>
                </Flex>
              )}

              <FieldArray name="add_cost">
                {({ remove, push }) => (
                  <>
                    {values.add_cost.map((addCost, index) => (
                      <SimpleGrid
                        columns={3}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                        key={index}
                      >
                        <GridItem>
                          <FormLabel>Type</FormLabel>
                          <Input
                            value={addCost.type}
                            readOnly
                            _readOnly={readOnlyColor}
                          />
                        </GridItem>
                        <GridItem>
                          <FormLabel>Harga Nego</FormLabel>
                          <Flex>
                            <Input
                              value={addCost.price}
                              readOnly
                              _readOnly={readOnlyColor}
                            />
                          </Flex>
                        </GridItem>
                        <GridItem>
                          <FormControl isRequired>
                            <FormLabel>Harga Final</FormLabel>
                            <Flex>
                              <Field
                                name={`add_cost[${index}].final_price`}
                                as={Input}
                                placeholder="Enter price"
                              />
                            </Flex>
                          </FormControl>
                        </GridItem>
                      </SimpleGrid>
                    ))}
                  </>
                )}
              </FieldArray>

              <Box textAlign="right">
                <Button colorScheme="purple" type="submit">
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </ChakraProvider>
  );
};

export default FormComponent;
