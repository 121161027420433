// index.js
import { Box, Flex, Spacer, Button, Text, useToast } from '@chakra-ui/react';
import { columnsDataDevelopment } from './columnsData';
import React, { useEffect, useState } from 'react';
import Pagination from 'components/footer/Pagination';
import Filter from 'components/menu/FilterMenu';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import IdeationTable from './IdeationTable';
import Loader from 'components/loader/Loader';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useIdeation from 'hooks/useIdeation';
import IdeationAddForm from './IdeationAddForm';

export default function Ideation() {
  const { isLoading, ideation, totalIdeation, getIdeation } = useIdeation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const page = queryParams.get('page') || 1;
  const limit = queryParams.get('limit') || 10;

  const handleRefresh = () => {
    window.location.reload();
  };

  useEffect(() => {
    getIdeation(page, limit);
  }, [page]);

  // State for managing the brief window visibility and minimized state
  const [isBriefOpen, setIsBriefOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  const handleAddNewClick = () => {
    setIsBriefOpen(true);
    setIsMinimized(false); // Ensure it's maximized when opened
  };

  return (
    <>
      {isLoading ? (
        <Loader opacity={0.2}></Loader>
      ) : (
        <>
          <Text fontWeight="bold" fontSize="34px">
            Ideation
          </Text>
          <Box pt={{ base: '130px', md: '30px', xl: '30px' }}>
            <Flex px="25px" mb="20px" align="center">
              <Flex align="center">
                <SearchBar me="10px" borderRadius="30px" />
                <Filter />
              </Flex>
              <Spacer />
              <Button
                onClick={handleRefresh}
                bgColor="purple.300"
                color="white"
                mr={2}
              >
                Refresh
              </Button>
              <Button
                onClick={handleAddNewClick}
                bgColor="purple.500"
                color="white"
              >
                Add New
              </Button>
            </Flex>
            <IdeationTable
              columnsData={columnsDataDevelopment}
              tableData={ideation}
            />
            <Spacer mt={4} />
            <Pagination totalData={totalIdeation} rowsPerPage={limit} />
          </Box>

          {/* Conditionally render the BriefForm in bottom-right corner */}
          {isBriefOpen && (
            <Box
              position="fixed"
              bottom={isMinimized ? '10px' : '0'}
              right="10px"
              w={isMinimized ? '200px' : '1000px'}
              h={isMinimized ? '50px' : '600px'}
              bg="white"
              boxShadow="lg"
              borderRadius="lg"
              overflow="hidden"
              transition="all 0.3s ease"
            >
              <Flex
                justify="space-between"
                align="center"
                p={3}
                bg="purple.500"
                color="white"
              >
                <Text>{isMinimized ? 'Brief' : 'Add New Ideation'}</Text>
                <Flex>
                  <Button
                    size="sm"
                    onClick={() => setIsMinimized(!isMinimized)}
                    bgColor="purple.300"
                    mr={2}
                  >
                    {isMinimized ? 'Maximize' : 'Minimize'}
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => setIsBriefOpen(false)}
                    bgColor="red.500"
                  >
                    Close
                  </Button>
                </Flex>
              </Flex>
              {!isMinimized && <IdeationAddForm />}
            </Box>
          )}
        </>
      )}
    </>
  );
}
